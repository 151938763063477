import CustomConfigurator from "CustomConfigurator";
import BitkomCampaignInvite from "../components/BitkomCampaignInvite";
import BitkomDetailParticipation from "../components/BitkomDetailParticipation";
import BitkomCampaignForm from "../components/appointments/form/BitkomCampaignForm";
import BitkomCampaignPage from "../components/appointments/details/BitkomCampaignPage";

CustomConfigurator.set("markdownEditor.colors", []);
CustomConfigurator.set("markdownEditor.buttons", [
  "BoldButton",
  "ItalicButton",
  "UnderlineButton",
  "StrikeButton",
  "Separator",
  "MarkButton",
  "CodeButton",
  "Separator",
  "H2Button",
  "Separator",
  "UnorderedListButton",
  "OrderedListButton",
  "BlockquoteButton",
  "Separator",
  "LinkButton",
]);

// Add component to lookup used when rendering the list of invites
CustomConfigurator.set(
  ["invites", "types", "appointments", "invites", "Bitkom::Campaign"],
  BitkomCampaignInvite,
);
CustomConfigurator.set(
  [
    "invites",
    "types",
    "appointments",
    "participations",
    "Bitkom::DetailParticipation",
  ],
  BitkomDetailParticipation,
);

CustomConfigurator.set(
  ["appointments", "edit", "form", "BitkomCampaignForm"],
  BitkomCampaignForm,
);

CustomConfigurator.set(
  ["appointments", "show", "BitkomCampaign"],
  BitkomCampaignPage,
);
