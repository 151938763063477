import React from "react";
import { isSameDay } from "date-fns";

import formatDate from "helpers/formatDate";
import { AppointmentDate } from "../../../../../../../app/javascript/@types/appointments";

type AppointmentDateArgs = {
  date: AppointmentDate;
};

export function useAppointmentDate(date: AppointmentDate) {
  const appointmentStart = new Date(date.start);
  const appointmentEnd = date.end ? new Date(date.end) : null;
  const formattedStart = formatDate(
    appointmentStart,
    date.all_day ? "PPPP" : I18n.t("js.time.formats.date_fns.long_datetime"),
  );

  const formattedEnd = appointmentEnd
    ? isSameDay(appointmentStart, appointmentEnd)
      ? formatDate(appointmentEnd, "HH:mm")
      : formatDate(
          appointmentEnd,
          date.all_day
            ? "PPPP"
            : I18n.t("js.time.formats.date_fns.long_datetime"),
        )
    : null;

  return { appointmentStart, appointmentEnd, formattedStart, formattedEnd };
}

export default function BitkomCampaignDetailDate({
  date,
}: AppointmentDateArgs) {
  const { appointmentStart, appointmentEnd, formattedStart, formattedEnd } =
    useAppointmentDate(date);

  return (
    <>
      {appointmentEnd && isSameDay(appointmentStart, appointmentEnd) ? (
        <div className="flex gap-1">
          <time>{formatDate(appointmentStart, "PPPP")}</time>
          <time>{formatDate(appointmentStart, "HH:mm")}</time> bis
          <time>{formatDate(appointmentEnd, "HH:mm")}</time>
        </div>
      ) : (
        <>
          <div className="flex gap-1">
            {`${I18n.t("js.calendars.date_range.start")}: `}
            <time>{formattedStart}</time>
          </div>
          {formattedEnd && (
            <div className="flex gap-1">
              {`${I18n.t("js.calendars.date_range.end")}: `}
              <time>{formattedEnd}</time>
            </div>
          )}
        </>
      )}
    </>
  );
}
