import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Appointment } from "components/../@types/appointments";
import { concat, isEmpty, map, some } from "lodash";
import FileUploadField from "components/shared/form/fields/FileUploadField";
import { useDispatch } from "react-redux";
import publishAllVolatile from "helpers/files/publishAllVolatile";
import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import FormActions from "components/shared/form/fields/FormActions";
import { useNavigate } from "helpers/tixxt-router";

export type AppointmentFormData = {
  event_files: File[];
};

type AppointmentFormArgs = {
  appointment: Appointment;
};

export function updateBitkomCampaign({ appointmentId, ...body }) {
  return fetchApi(`/custom/bitkom/campaigns/${appointmentId}`, {
    method: "PUT",
    body,
  });
}

export default function BitkomCampaignForm({
  appointment,
}: AppointmentFormArgs) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate: onUpdateBitkomCampaign, isLoading: isUpdating } = useMutation(
    updateBitkomCampaign,
    {
      onSuccess,
      onError,
    },
  );

  const [filesLoading, setFilesLoading] = useState(false);

  const { handleSubmit, control } = useForm<AppointmentFormData>({
    defaultValues: {
      event_files: appointment.event_files || [],
    },
  });

  const selectedEventFiles = useWatch({ control, name: "event_files" });

  function onSuccess(newAppointment: Appointment) {
    location.replace(`/appointments/${newAppointment.id}`);
  }

  function onError(error) {
    toastr.error(error.message || I18n.t("generic_error"));
  }

  useEffect(() => {
    setFilesLoading(
      some(concat(selectedEventFiles), {
        state: "uploading",
      }),
    );
  }, [selectedEventFiles]);

  async function submit(data: AppointmentFormData) {
    const newAppointmentData: any = { ...data };

    if (!isEmpty(selectedEventFiles)) {
      const result = await publishAllVolatile({
        dispatch,
        values: {
          event_files: selectedEventFiles,
        },
        storageDirectory: "files",
      });

      newAppointmentData.event_files = map(result.event_files, (file) =>
        file.state
          ? { ...file, file_object_id: file.id, id: null, type: "file" }
          : file,
      );
    } else {
      newAppointmentData.event_files = [];
    }

    onUpdateBitkomCampaign({
      appointmentId: appointment.id,
      ...newAppointmentData,
    });
  }

  return (
    <form className="form-horizontal mb-6" onSubmit={handleSubmit(submit)}>
      <FileUploadField
        control={control}
        name={"event_files"}
        label={I18n.t("js.calendars.appointment.files.label")}
        options={{
          multiple: true,
          selectExisting: true,
        }}
      />
      <FormActions
        onCancel={(e) => {
          e.preventDefault();
          navigate(`/appointments/${appointment.id}`);
        }}
        saveDisabled={isUpdating || filesLoading}
      />
    </form>
  );
}
