import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Select from "components/shared/form/fields/Select";
import TextInput from "components/shared/form/fields/TextInput";
import FormActions from "components/shared/form/fields/FormActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Escort } from "../types";
import { omit } from "lodash";
import { useRemoveEscort, useUpdateParticipation } from "../api";

type EscortFormFieldArgs = {
  first_name: string;
  last_name: string;
  title: { label: string; value: string };
  salutation: { label: string; value: string } | null;
  company: string;
};

type EscortFormArgs = {
  refetchCampaign: () => void;
  setShowEscortModal: (close: boolean) => void;
  detailId?: string;
  campaignId?: string;
  escort?: Escort | null;
};

export default function EscortForm({
  detailId = "",
  campaignId = "",
  escort,
  refetchCampaign,
  setShowEscortModal,
}: EscortFormArgs) {
  const { mutate: updateParticipation, isLoading: isUpdating } =
    useUpdateParticipation(campaignId, detailId, {
      onSuccess: () => {
        setShowEscortModal(false);
        refetchCampaign();
      },
    });

  const { mutate: destroyEscort, isLoading: isDestroyingEscort } =
    useRemoveEscort(campaignId, detailId, {
      onSuccess: () => {
        setShowEscortModal(false);
        refetchCampaign();
      },
    });

  const methods = useForm<EscortFormFieldArgs>({
    defaultValues: {
      salutation: escort?.salutation
        ? { label: escort?.salutation, value: escort?.salutation }
        : null,
      title: { label: escort?.title || "-", value: escort?.title || "" },
      first_name: escort?.first_name || "",
      last_name: escort?.last_name || "",
      company: escort?.company || "",
    },
  });
  const submit = (data: EscortFormFieldArgs) => {
    const escortData = {
      ...omit(data, ["salutation", "title"]),
      salutation: data.salutation?.value || null,
      title: data.title.value,
    };

    const body = {
      participation: {
        escort_attributes: escortData,
      },
    };

    updateParticipation(body);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="form-horizontal escort-form"
        onSubmit={methods.handleSubmit(submit)}
      >
        <Select
          name="salutation"
          label="Anrede"
          options={[
            { label: "Frau", value: "Frau" },
            { label: "Herr", value: "Herr" },
          ]}
          required
        />
        <Select
          name="title"
          label="Titel"
          options={[
            { label: "-", value: "" },
            { label: "Dr.", value: "Dr." },
            { label: "Prof.", value: "Prof." },
            { label: "Prof. Dr.", value: "Prof. Dr." },
          ]}
        />
        <TextInput label="Vorname" name="first_name" required />
        <TextInput label="Nachname" name="last_name" required />
        <TextInput label="Unternehmen" name="company" />
        <FormActions showCancel={false} showSave={false}>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isUpdating || isDestroyingEscort}
          >
            Speichern
          </button>
          <button
            className="btn btn-danger pull-right"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              destroyEscort();
            }}
            disabled={isUpdating || isDestroyingEscort}
          >
            <FontAwesomeIcon icon={faTrash} /> Begleitperson entfernen
          </button>
        </FormActions>
      </form>
    </FormProvider>
  );
}
