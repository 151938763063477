import { BitkomCampaignDetail } from "../types";
import { includes, isEmpty } from "lodash";
import moment from "moment/moment";
import React, { useState } from "react";
import Modal from "components/shared/Modal";
import EscortForm from "./EscortForm";
import { useParams } from "helpers/tixxt-router";
import classNames from "classnames";
import { useUpdateParticipation } from "../api";
import formatDate from "helpers/formatDate";
import BitkomCampaignDetailDate from "./BitkomCampaignDetailDate";

export default function DetailView({
  detail,
  refetchCampaign,
}: {
  detail: BitkomCampaignDetail;
  refetchCampaign: () => void;
}) {
  const { id: campaignId } = useParams();
  const [showEscortModal, setShowEscortModal] = useState(false);
  const isOrgaItem = detail.type === "Organisationspunkt";

  const { mutate: updateParticipation, isLoading: isUpdating } =
    useUpdateParticipation(campaignId || "", detail.id, {
      onSuccess: () => {
        setShowEscortModal(false);
        refetchCampaign();
      },
    });

  function ParticipationActionButton({
    type,
    text,
    disabled = false,
  }: {
    type: string;
    text: string;
    disabled?: boolean;
  }) {
    const ACTION_LOOKUP = {
      withdraw: "open",
      accept: "accepted",
      decline: "declined",
    };
    return (
      <button
        className={classNames("btn btn-sm", {
          "btn-light": includes(["withdraw", "decline"], type),
          "btn-primary": type === "accept",
        })}
        onClick={(e) => {
          e.preventDefault();
          updateParticipation({
            participation: {
              status: ACTION_LOOKUP[type],
            },
          });
        }}
        disabled={disabled || isUpdating}
      >
        {text}
      </button>
    );
  }

  return (
    <>
      {showEscortModal && (
        <Modal
          onClose={() => {
            setShowEscortModal(false);
          }}
          title="Begleitperson"
        >
          <EscortForm
            campaignId={campaignId}
            detailId={detail.id}
            escort={detail.my_participation?.escort}
            refetchCampaign={refetchCampaign}
            setShowEscortModal={setShowEscortModal}
          />
        </Modal>
      )}
      <div className="detail-view border-box p-4">
        <h3>{detail.name}</h3>
        <div className="flex mb-4">
          <div className="grow">
            {!isOrgaItem && detail.date.start && (
              <BitkomCampaignDetailDate date={detail.date} />
            )}
            {detail.location && (
              <div className="location">{detail.location}</div>
            )}
            {detail.total_seats > 0 && (
              <div className="seats">
                Gesamtplatzanzahl: {detail.total_seats}
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center justify-end no-shrink">
            <span>
              {detail.my_participation?.status === "accepted" &&
                "Sie haben zugesagt."}
              {detail.my_participation?.status === "declined" &&
                "Sie haben abgesagt."}
              {detail.my_participation?.status === "queued" &&
                "Sie sind auf der Warteliste."}
            </span>
            <div className="btn-group">
              {detail.my_participation?.status === "accepted" &&
                detail.can.opt && (
                  <ParticipationActionButton
                    type="withdraw"
                    text={
                      isOrgaItem ? '"Ja" zurücknehmen' : "Zusage zurücknehmen"
                    }
                  />
                )}
              {detail.my_participation?.status === "declined" &&
                detail.can.opt && (
                  <ParticipationActionButton
                    type="withdraw"
                    text={
                      isOrgaItem ? '"Nein" zurücknehmen' : "Absage zurücknehmen"
                    }
                  />
                )}
              {!includes(
                ["accepted", "declined"],
                detail.my_participation?.status,
              ) && (
                <>
                  <ParticipationActionButton
                    type="decline"
                    text={isOrgaItem ? "Nein" : "Absagen"}
                    disabled={!detail.can.opt}
                  />
                  <ParticipationActionButton
                    type="accept"
                    text={isOrgaItem ? "Ja" : "Zusagen"}
                    disabled={!detail.can.opt}
                  />
                </>
              )}
              {detail.allow_escort &&
                (detail.my_participation?.status === "accepted" ||
                  detail.my_participation?.status === "queued") && (
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => setShowEscortModal(true)}
                  >
                    {isEmpty(detail.my_participation.escort)
                      ? "Begleitperson anmelden"
                      : "Begleitperson ändern"}
                  </button>
                )}
            </div>
          </div>
        </div>
        {!detail.user_can_opt && detail.registration_closed ? (
          <div className="alert alert-warning alert-inline">
            Die Veranstaltung ist leider bereits ausgebucht.
          </div>
        ) : !detail.user_can_opt && !detail.my_participation?.status ? (
          <div className="alert alert-warning alert-inline">
            Eine Teilnahme ist nur mit persönlicher Einladung möglich.
          </div>
        ) : detail.my_registration_deadline ? (
          moment(detail.my_registration_deadline) > moment() ? (
            <div className="alert alert-info alert-inline">
              Anmelden bis{" "}
              {formatDate(
                new Date(detail.my_registration_deadline),
                "PPPP HH:mm",
              )}
              .
            </div>
          ) : (
            <div className="alert alert-warning alert-inline">
              {detail.registration_deadline_text ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: detail.registration_deadline_text,
                  }}
                ></div>
              ) : (
                "Die Anmeldefrist ist abgelaufen."
              )}
            </div>
          )
        ) : null}
        <p style={{ whiteSpace: "pre-wrap" }}>{detail.description}</p>
      </div>
    </>
  );
}
