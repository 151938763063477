import { BitkomCampaignDetail } from "../types";
import { entries, groupBy, map, mapValues } from "lodash";
import React from "react";
import DetailView from "./BitkomCampaignDetail";

function groupDetails(details: BitkomCampaignDetail[]): {
  [p: string]: BitkomCampaignDetail[];
} {
  const grouped = groupBy(details, "group");

  return mapValues(grouped, (groupDetails) => {
    return [...groupDetails].sort((a, b) => a.sort.localeCompare(b.sort));
  });
}

export default function BitkomEventDetails({
  details,
  refetchCampaign,
}: {
  details: BitkomCampaignDetail[];
  refetchCampaign: () => void;
}) {
  const groupedDetails = groupDetails(details);

  return (
    <div className="event-details space-y-8">
      {map(entries(groupedDetails), ([group, details]) => {
        const Component =
          details.length > 1 ? GroupedDetailsView : UngroupedDetailsView;
        return (
          <Component
            key={group}
            details={details}
            refetchCampaign={refetchCampaign}
          />
        );
      })}
    </div>
  );
}

function GroupedDetailsView({
  details,
  refetchCampaign,
}: {
  refetchCampaign: () => void;
  details: BitkomCampaignDetail[];
}) {
  return (
    <div className="appointment-details-group">
      <h5>
        Parallel laufende Programmpunkte - es kann nur eine der folgenden
        Optionen ausgewählt werden:
      </h5>
      <div className="grouped-event-details border-l-[6px] pl-4 border-primary space-y-4">
        <UngroupedDetailsView
          details={details}
          refetchCampaign={refetchCampaign}
        />
      </div>
    </div>
  );
}

function UngroupedDetailsView({
  details,
  refetchCampaign,
}: {
  refetchCampaign: () => void;
  details: BitkomCampaignDetail[];
}) {
  return details.map((detail) => (
    <DetailView
      key={detail.id}
      detail={detail}
      refetchCampaign={refetchCampaign}
    />
  ));
}
