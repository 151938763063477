import { useMutation, UseMutationOptions } from "react-query";
import { BitkomCampaignDetail, Escort } from "./types";
import { fetchApi } from "helpers/reactQueryApi";

function getParticipationUrl({
  campaignId,
  detailId,
}: {
  campaignId?: string;
  detailId: string;
}) {
  return `/custom/bitkom/campaigns/${campaignId}/details/${detailId}/my_participation`;
}

export function useUpdateParticipation(
  campaignId: string,
  detailId: string,
  options: { onSuccess: () => void },
) {
  return useMutation(
    (body: {
      participation: {
        status?: "accepted" | "declined" | "open";
        escort_attributes?: Escort;
      };
    }) =>
      fetchApi(getParticipationUrl({ campaignId, detailId }), {
        method: "PUT",
        body,
      }),
    options,
  );
}

export function useRemoveEscort(
  campaignId: string,
  detailId: string,
  options: { onSuccess: () => void },
) {
  return useMutation(
    () =>
      fetchApi(getParticipationUrl({ campaignId, detailId }) + "/escort", {
        method: "DELETE",
      }),
    options,
  );
}
