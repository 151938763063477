import React from "react";
import { useParams } from "helpers/tixxt-router";
import { useAppointment, useCalendars } from "components/appointments/api";
import PageLoading from "components/shared/PageLoading";
import PageTitle, { Subtitle } from "components/layout/PageTitle";
import { filter, includes, isEmpty, map, pick, values } from "lodash";
import Toolbar from "components/appointments/details/Toolbar";
import { BitkomCampaign } from "../types";
import BitkomEventDetails from "./BitkomCampaignDetails";
import AppointmentDate from "components/appointments/details/AppointmentDate";
import AppointmentLocation from "components/appointments/details/AppointmentLocation";
import formatDate from "helpers/formatDate";
import FileRow from "components/shared/FileRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCoffee,
  faHome,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";

export default function BitkomCampaignPage() {
  const { id } = useParams();
  const {
    data: appointment,
    refetch,
    isLoading,
  } = useAppointment<BitkomCampaign>(id);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    appointment && (
      <>
        <PageTitle title={appointment?.name} />
        <Subtitle className="mb-3">
          {I18n.t("js.calendars.appointment.subtitle")}
        </Subtitle>
        <Toolbar
          id={appointment.id}
          type={appointment.type}
          can={appointment.can}
          name={appointment.name}
          refetch={refetch}
        />
        <BitkomCampaignDetails {...appointment} refetch={refetch} />
      </>
    )
  );
}

export function BitkomCampaignDetails({
  refetch,
  ...appointment
}: BitkomCampaign & {
  refetch: () => void;
}) {
  const { data: calendars } = useCalendars();

  const appointmentCalendars = filter(calendars, (cal) =>
    includes(appointment.calendar_ids, cal.id),
  );

  const addressForUri = encodeURIComponent(
    values(
      pick(location, ["address1", "address2", "zip", "city", "country"]),
    ).join(" "),
  );

  return (
    <>
      <div className="space-y-8">
        <div className="border-box bg-primary/10 border-primary/25 p-4 text-sm">
          <div className="grid sm:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="detail-calendars">
                <ol className="calendar-list flex flex-wrap gap-2 mb-8">
                  {map(appointmentCalendars, (cal) => (
                    <li className="flex gap-1 items-center" key={cal.id}>
                      <i
                        className="w-4 h-4 block rounded-full"
                        style={{ backgroundColor: `#${cal.color}` }}
                      />
                      {cal.name}
                    </li>
                  ))}
                </ol>
              </div>
              <AppointmentDate date={appointment.date} />
              <AppointmentLocation {...appointment.location} />
            </div>
            <div>
              {appointment.location.maps_url && (
                <div className="detail-map">
                  <a
                    href={`https://www.google.de/maps?q=${addressForUri}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appointment.location.maps_url}
                      className="w-full"
                    />
                  </a>
                </div>
              )}
            </div>
          </div>

          {(appointment.registration_deadline ||
            appointment.ticket_url ||
            appointment.registration_with_costs) && (
            <div className="-mx-4 px-4 pt-4 mt-4 border-neutral border-dashed border-t overflow-hidden">
              {appointment.ticket_url && (
                <a
                  href={appointment.ticket_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary float-right flex items-center gap-2"
                >
                  <FontAwesomeIcon icon={faArrowRight} className={"w-4 h-4"} />
                  <span>Zur Anmeldung</span>
                </a>
              )}
              {appointment.registration_with_costs && (
                <div>Die Teilnahme ist kostenpflichtig.</div>
              )}
              {appointment.registration_deadline && (
                <div>
                  Anmelden bis{" "}
                  {formatDate(
                    new Date(appointment.registration_deadline),
                    "PPPP HH:mm",
                  )}
                  .
                </div>
              )}
            </div>
          )}
        </div>
        <div className="detail-description">
          {appointment.description && (
            <p style={{ whiteSpace: "pre-wrap" }}>{appointment.description}</p>
          )}
        </div>
        <BitkomEventDetails
          details={appointment.details}
          refetchCampaign={refetch}
        />
        {appointment.description_bottom && (
          <div className="detail-description-bottom">
            <p style={{ whiteSpace: "pre-wrap" }}>
              {appointment.description_bottom}
            </p>
          </div>
        )}
        {!isEmpty(appointment.event_files) && (
          <div className="detail-wrap">
            <strong>Dateien</strong>
            {map(appointment.event_files, (file, index) => (
              <FileRow
                key={index}
                {...file}
                name={file.name || "gelöschte Datei"}
              />
            ))}
          </div>
        )}
        <div className="grid grid-cols-2 gap-6">
          <div>
            {appointment.category && (
              <div className="detail-wrap">
                <strong>Kategorie</strong>
                <div className="detail-block category flex items-center gap-2">
                  <FontAwesomeIcon icon={faCoffee} className={"detail-icon"} />
                  {appointment.category}
                </div>
              </div>
            )}

            {appointment.organizer && (
              <div className="detail-wrap">
                <strong>Veranstalter</strong>
                <div className="detail-block organizer flex items-center gap-2">
                  <FontAwesomeIcon icon={faHome} className={"detail-icon"} />
                  {appointment.organizer}
                </div>
              </div>
            )}
          </div>

          <div>
            {appointment.contact_functional && (
              <div className="detail-wrap">
                <strong>Fachlicher Ansprechpartner</strong>
                <div className="detail-block flex items-center gap-2">
                  <FontAwesomeIcon icon={faPhone} className={"detail-icon"} />
                  <a href={appointment.contact_functional.path}>
                    {appointment.contact_functional.name}
                  </a>
                </div>
              </div>
            )}

            {appointment.contact_organizational && (
              <div className="detail-wrap">
                <strong>Event-Ansprechpartner</strong>
                <div className="detail-block flex items-center gap-2">
                  <FontAwesomeIcon icon={faPhone} className={"detail-icon"} />
                  <a href={appointment.contact_organizational.path}>
                    {appointment.contact_organizational.name}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
